body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backlink{
  margin-left: 20px;
  font-size: 20px;
}
.backlink:hover
{
  color:red;
  text-shadow: 1px 1px blue;
}

.lbl{
  color: black;
  font-size: 20px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.selbox{
  cursor: pointer;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;    /* Centers vertically */
  height: 50px; 
  box-shadow: -1px -1px blue;
}
.selbox h5{
  text-align: center;
}
.selbox:hover{
  box-shadow: 1px 1px red;
}