@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {box-sizing: border-box;}
.txt-ttl{
  font-size: 1rem;
}
#HeadText{
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 3px;
    background: linear-gradient(90deg, #ff6a00, #ee0979, #ff6a00);
    background-size: 200%;
    background-clip: border-box;
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
    display: inline-block;
    padding: 5px;
    border-radius: 2px;
}

#accordionSidebar{
    background:linear-gradient(to bottom , rgb(134, 9, 251),orange);
    font-family: "Segoe UI Regular" !important ;
}


#accordionSidebar .nav-item .nav-link span{
    letter-spacing: 0.5px;
    font-weight: 500;
}

#sidebarToggle{
    background:rgb(255, 140, 0);
}

.strategy .toggleswitchUser{
    display: flex;
    gap: 10px;
    color: green;
    letter-spacing: 2px;
    font-family: 'Times New Roman', Times, serif;
}

.toggleswitchUser .checkbox {
    opacity: 0;
    position: absolute;
  }
  
  .toggleswitchUser .checkbox-label {
    background-color: #18a203;
    width: 30px;
    height: 16px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .fa-moon {color: #f1c40f;}
  
  .fa-sun {color: #f39c12;}
  
  .checkbox-label .ball {
    background-color: #fff;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  
  .checkbox:checked + .checkbox-label .ball {
    transform: translateX(24px);
  }

  /* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #df0505;
  color: rgb(255, 255, 255);
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Icon styling */
.navbar-icons {
  display: flex;
  gap: 1.5rem;
}

.icon-container {
  position: relative;
  font-size: 1.5rem;
  cursor: pointer;
}
/* Profile icon customization */
.profile {
  color: black;
  font-size: 1rem;
}

/* Dropdown menu */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 10px;
  left: -140px;
  background-color: white;
  color: #333;
  width: 150px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu ul li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdown-menu ul li:hover {
  background-color: #f1f1f1;
}

/* Show dropdown on hover */
.profile:hover .dropdown-menu {
  display: block;
}

/* Responsive navbar */
@media (max-width: 768px) {
  .navbar-icons {
    gap: 1rem;
  }

  .navbar-brand {
    font-size: 1.2rem;
  }

  .profile {
    font-size: 1.8rem;
  }
}



/* stategies css */
.form-select, .form-control {
  padding: 8px;
  border-radius: 5px;
  font-size: 0.9rem; /* Adjust font size for better readability */
}

.shadow-sm {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Slightly deeper shadow for a modern look */
}

.action-icon {
  cursor: pointer;
  font-size: 1.5rem;
}

.action-icon.mx-2 {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 576px) {
  .form-select, .form-control {
      padding: 6px;
  }
  
  .action-icon {
      font-size: 1.25rem;
  }
}
.time-input {
  border: 1px solid #ccc;
  background-color: #fff;
  font-size: 1rem;
  padding: 10px;
  border-radius: 8px;
  transition: border-color 0.3s ease-in-out;
}

.time-input:focus {
  border-color: #3f51b5; /* Material UI color */
  box-shadow: 0 0 8px rgba(63, 81, 181, 0.4);
  outline: none;
}

.strategy .form-check-input {
  margin-right: 10px;
}

.strategy  .form-control {
  border-radius: 0.375rem;
  border: 1px solid #ced4da;
  padding: 0.5rem;
}
.setting-ttl{
  font-size: 1.5rem;
  color: #2956ea;
}

.profiler {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.profiler .modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: max-content;
  max-height: 80vh;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  position: relative; /* Ensures close button stays within modal */
  overflow: scroll;
  scrollbar-width: none;
  
}

.container-main {
  display: flex;
  height: 100%;
  padding: 10px;
}

.profiler .container-main .left-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 30%;
  padding: 20px;
}

.profiler .container-main .image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profiler .container-main .image-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profiler .container-main .image {
  border-radius: 50%;
  width: 90%;
  height: 90%;
}

.profiler .container-main .separator {
  width: 80%;
  height: 2px;
  background-color: black;
}

.profiler .container-main .right-panel {
  width: 70%;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .profiler .container-main {
    flex-direction: column;
  }

  .profiler .container-main .left-panel,.profiler .right-panel {
    width: 100%;
    height: 50vh;
  }
}
