#reglogformmain{
  padding: 0;
  min-height:400px; 
  height: 100vh;
  width: 100%;
}
.checkbox {
    opacity: 0;
    position: absolute;
  }
  
 .reglogcommon .checkbox-label {
    background-color: #fff7f7;
    width: 50px;
    height: 26px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .reglogcommon .checkbox-label .ball {
    background-color: #0d0f10;
    width: 22px;
    height: 22px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  
 .reglogcommon .checkbox:checked + .checkbox-label .ball {
    transform: translateX(24px);
  }


  /* .reglog{
    padding:10px;
  } */

  .reglogcommon .toptog{
    max-width: 400px;
    /* margin-left:10px; */
    border-bottom:2px solid grey;
  }

  .reglogcommon  #registrationForm{
    letter-spacing: 3px;
    color: #0c0c0c;
    font-weight: 600;
    transition: 0.3s;
    text-align: center;
    border-radius: 20px;
  }
  
  .reglogcommon  #loginForm{
    text-align: center;
    letter-spacing: 3px;
    color: rgb(0, 0, 0);
    font-weight: 600;
    transition: 0.3s;
    border-radius: 20px;
    transition: 0.3s;
  }


  .reglogcommon #commonform {
  height:100%;
  background: linear-gradient(to bottom,rgb(229,104,7),rgb(106,8,158));
  position: relative;
  padding-bottom: 10px;
  }


  .reglogcommon .back_button{
    position: absolute;
    left: 15px;
    top:7px;
    font-size: 30px;
    cursor:pointer;
    border-radius: 50%;
  }
  .reglogcommon .back_arrow{
    text-decoration: none;
    color: #0c0c0c;
    font-size: 30px;
  }


  .reglogcommon .formbtn {
    border: calc(0.05 * var(--m)) solid transparent;
    border-top: none;
    position: relative;
    color: #ffffff;
    letter-spacing: 2px;
    font-size: 16px;
    border-radius: calc(0.7 * var(--m));
    font-weight: 400;
    font-family: "Segoe UI Regular";
    background:linear-gradient(#060504f2, #000000), linear-gradient(#121213 50%, rgba(18, 18, 19, 0.6) 80%, rgba(17, 17, 233, 0)),  linear-gradient(90deg, var(--orange), var(--yellow), var(--green), var(--blue), var(--purple), var(--pink), var(--red));
    background-origin: border-box;
    background-clip: padding-box, border-box, border-box;
    background-size: 200%;
    animation: animate 2s infinite linear;
}

  .reglogcommon  .formbtn:hover, .formbtn:hover::before{
    animation: pop 1.5s infinite linear;
    font-weight: 400;
  }

  .forget-pass{
    font-weight: 100;
    background: rgba(255, 255, 255, 0.245);
    padding: 5px;
    border-radius: 5px;
    /* text-decoration: underline ;
    text-decoration-color: rgb(255, 170, 0); */
    cursor: pointer;
    transition: 0.5s;
    letter-spacing: 2px;
    font-size: x-small;
    font-style: italic;
    text-transform: uppercase;
  }
  .forget-pass:hover{
    border:0.2px solid rgb(200, 109, 4) ;
  }

  @keyframes pop {
    0% {background-position: 0}
    100% {background-position: 200%}
  }
  @media screen and (max-width: 1000px) {
    :root {
      --m: 2rem;
    }
  }

