.open-btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .open-btn:hover {
    background-color: #0056b3;
  }
  
  /* Modal styles */
  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: relative; /* Ensures close button stays within modal */
  }
  .radio-group {
    display: flex;
    justify-content: space-between;
  }
  
  label {
    display: flex;
    align-items: center;
  }
  
  input[type="radio"] {
    margin-right: 10px;
  }
  
  .submit-btn {
    display: block;
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .submit-btn:hover {
    background-color: #218838;
  }
  
  .list {
    margin-top: 20px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }
  
  /* Close button */
  .close {
    position:fixed;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
    color: #555;
  }
  
  .close:hover {
    color: black;
  }
  
  /* Responsive behavior */
  @media only screen and (max-width: 600px) {
    .modal-content {
      padding: 20px;
      width: 100%;
    }
  
    h2 {
      font-size: 20px;
    }
  
    .submit-btn {
      font-size: 16px;
    }
  }