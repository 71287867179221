/* General styles */
  
  .pricing-plans-container {
    text-align: center;
    padding: 0.5rem;
  }
  
  .pricing-title {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight:600;
    background-image: linear-gradient(rgb(166, 22, 228), rgb(255, 162, 0));
    color: transparent;
    background-clip: text;
  }
  
  /* Pricing Cards */
  .pricing-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
  }
  
  .pricing-card {
    border-top: #cd5d05;
    background-color: #fff;
    border-radius: 10px;
    padding: 1.5rem;
    width: 100%;
    max-width: 300px;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
  }
  
  .pricing-card:hover {
    transform: translateY(-10px);
    color: black;
    background-color: #f4a0602f;
  }
  
  .premium-card {
    border: 2px solid #49d6a9;
  }
  
  /* Plan Titles */
  .plan-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #cd5d05;
  }
  
  .premium-card .plan-title {
    color: #32a852;
  }
  
  .plan-price {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .original-price {
    text-decoration: line-through;
    margin-right: 0.5rem;
    color: #999;
  }
  
  .discounted-price {
    font-weight: bold;
    color: #3c2b58;
  }
  
  /* Features List */
  .plan-features {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }
  
  .plan-features li {
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    color: #555;
  }
  
  /* Button */
  .see-features-btn {
    background-color: #cd5d05;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
  }
  
  .see-features-btn:hover {
    background-color: #ae0aea;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .pricing-title {
      font-size: 2rem;
    }
  
    .pricing-card {
      max-width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .pricing-cards {
      flex-direction: column;
      gap: 1rem;
    }
  }
  