
  #demat-link-btn{
    color: white;
    border-bottom: 0px;
    font-size: 15px;
    padding: 4px;
    text-align: center;
  }

  #login-link-btn{
    padding: 4px;
    color: aliceblue;
    border-bottom: 0px;    
    font-size: 15px;
  }

  .dropdown>.dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
      pointer-events: none;
  }
  .nav_btn_animate{
    font-weight: lighter;
    margin-left: 10px;
    border: calc(0.05 * var(--m)) solid transparent;
    position: relative;
    color: #ff3232;
    letter-spacing: 3px;
    font-size: var(--m);
    border-radius: calc(0.2 * var(--m));
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 0px 10px;
    width: 150px;
    background:linear-gradient(#000000, #8518d2), linear-gradient(#121213 50%, rgba(18, 18, 19, 0.6) 80%, rgba(18, 18, 19, 0)),  linear-gradient(90deg, var(--orange), var(--yellow), var(--green), var(--blue), var(--purple), var(--pink), var(--red));
    background-origin: border-box;
    background-clip: padding-box, border-box, border-box;
    background-size: 200%;
    animation: animate 2s infinite linear;
  }
  .nav_btn_animate::before {
    content: '';
    background: linear-gradient(90deg, var(--orange), var(--yellow), var(--green), var(--blue), var(--purple), var(--pink), var(--red));
    height: 30%;
    width: 60%;
    position: absolute;
    bottom: -20%;
    z-index: -5;
    background-size: 200%;
    animation: animate 2s infinite linear;
    filter: blur(calc(0.8 * var(--m)));
  }
  .nav_btn_animate:hover, .nav_btn_animate:hover::before {
    animation: animate 1.5s infinite linear;
    
    box-shadow: 0px 3px 10px 1px rgba(244, 92, 36, 0.409);
  }
  @keyframes animate {
    0% {background-position: 0}
    100% {background-position: 200%}
  }
  @media screen and (max-width: 1000px) {
    :root {
      --m: 2rem;
    }
  }
