.Broker .brokerlogo
{
    height: 50px;
    width: 50px;
    cursor: pointer;
    aspect-ratio: 6/7;
    object-fit: contain;
    /* mix-blend-mode: darken; */
}
.Broker .brokerlogo:hover
{
    box-shadow: 0px 0px 50px 0.5px rgb(193, 183, 180)
}