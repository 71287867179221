
.social-links-container {
  position: fixed; 
  bottom: 0;
  right: 0; 
  display: flex;
  align-items: center;
  margin: 0.5rem;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  border-top: 2px solid black;
  border-radius: 20px ;
}

.social-links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.social-links a {
  font-size: 1.5rem;
  transition: color 1ms;
}
.social-links a:nth-child(1){
  color: blue;
}
.social-links a:nth-child(2){
  color: rgb(1, 123, 22);

}
.social-links a:nth-child(3){
  color: rgb(255, 89, 0);
}
.social-links a:nth-child(4){
  color: red;
  background: line;
}




@media (max-width: 600px) {
  .social-links-container {
    flex-direction:row ;
    align-items: flex-end;
    background: transparent;
    border: none;
  }

  .social-links a{
    font-size: 1.2rem;
  }
  
}
