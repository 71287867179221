
footer .container .row h5 {
    font-size: 1rem;
    line-height: 1.375;
  font-family: "Segoe UI Regular";
    font-weight: 500;
    color: #f9b46a;
}

footer .container .row a {
  color: #000000;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
}

footer .container .row  a:hover {
  color: #00749f;
  text-decoration: none;
}

footer {
  background: transparent;
}
.footer_keys{
  /* padding-left: 150px; */
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

footer .container .row .foot_logo{
  margin-top: 0px;
  width: 100%;
  min-width:50px ;
  max-width: 200px; 
  mix-blend-mode: multiply; 
}
