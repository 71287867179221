@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {box-sizing: border-box;}

#HeadText{
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 400;
    letter-spacing: 3px;
    background: linear-gradient(90deg, #ff6a00, #ee0979, #ff6a00);
    background-size: 200%;
    background-clip: border-box;
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */
    display: inline-block;
    padding: 5px;
    border-radius: 2px;
}

#accordionSidebar{
    background:linear-gradient(to bottom , rgb(134, 9, 251),orange);
    font-family: "Segoe UI Regular" !important ;
}


#accordionSidebar .nav-item .nav-link span{
    letter-spacing: 0.5px;
    font-weight: 500;
}

#sidebarToggle{
    background:rgb(255, 140, 0);
}

.toggleswitchUser{
    display: flex;
    gap: 10px;
    /* color: green; */
    letter-spacing: 2px;
    font-family: 'Times New Roman', Times, serif;
}

.toggleswitchUser .checkbox {
    opacity: 0;
    position: absolute;
  }
  
  .toggleswitchUser .checkbox-label {
    background-color: #18a203;
    width: 41px;
    height: 18px;
    border-radius: 50px;
    position: relative;
    padding: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .fa-moon {color: #f1c40f;}
  
  .fa-sun {color: #f39c12;}
  
  .checkbox-label .ball {
    background-color: #fff;
    width: 14px;
    height: 14px;
    position: absolute;
    left: 2px;
    top: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
  }
  
  .checkbox:checked + .checkbox-label .ball {
    transform: translateX(24px);
  }

/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: #df0505;
  color: rgb(255, 255, 255);
  flex-wrap: wrap;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Icon styling */
.navbar-icons {
  display: flex;
  gap: 1.5rem;
}

.icon-container {
  position: relative;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Badge styling */
.badge {
  position: absolute;
  top: 0px;
  right: -8px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.2rem 0.4rem;
  font-size: 0.5rem;
}

/* Profile icon customization */
.profile {
  color: black;
  font-size: 1rem;
}

/* Dropdown menu */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 10px;
  left: -140px;
  background-color: white;
  color: #333;
  width: 150px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
}

.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu ul li {
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.dropdown-menu ul li:hover {
  background-color: #f1f1f1;
}

/* Show dropdown on hover */
.profile:hover .dropdown-menu {
  display: block;
}

/* Responsive navbar */
@media (max-width: 768px) {
  .navbar-icons {
    gap: 1rem;
  }

  .navbar-brand {
    font-size: 1.2rem;
  }

  .profile {
    font-size: 1.8rem;
  }
}


/* 
.custom-broker-terminal{
  background-color: rgb(43, 2, 19);
  border: 10px double rgb(126, 126, 255);
  border-radius: 80px;
  transition:1s;
}
.custom-broker-terminal:hover{
  background-color: rgb(0, 0, 0);
  border: 10px double rgb(3, 3, 250);
} */

.custom-broker-terminal{
  font-weight: lighter;
    border: calc(0.1 * var(--m)) solid transparent;
    position: relative;
    color: #ffffff;
    letter-spacing: 3px;
    border-radius: calc(0.2 * var(--m));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0px 10px;
    width: 100%;
    background:linear-gradient(#034b7e, #4c0566), linear-gradient(#3a3a3c 50%, rgba(18, 18, 19, 0.6) 80%, rgba(18, 18, 19, 0)),  linear-gradient(90deg, var(--orange), var(--yellow), var(--green), var(--blue), var(--purple), var(--pink), var(--red));
    background-origin: border-box;
    background-clip: padding-box, border-box, border-box;
    background-size: 200%;
    animation: animate 2s infinite linear;
  
}

.switch-broker-arrow{
  font-size: x-large;
  cursor: pointer;
  color: rgb(102, 78, 78);
}
.switch-broker-arrow:hover{
  color: #ff6a00;
}


.profile-circle{
  height: 35px;
  width: 35px;
  border-radius: 50%;
  border: 3px double rgb(2, 2, 86);
}
